import React from "react"
import {backgroundInformation} from "src/interfaces/publication"

const BackgroundDocuments = ({
  backgroundInformation,
}: {
  backgroundInformation: backgroundInformation[]
}) => {
  const activeInformation = backgroundInformation.filter(doc => {
    return doc.active
  })

  return (
    <>
      <h2 id="backgroundinformation" className="expandable">
        Achtergrondinformatie
      </h2>
      <div className="backgroundinformation">
        <ul>
          {activeInformation.map(doc => {
            return (
              <li key={doc.title}>
                <a
                  href={doc.document.url.replace(
                    "https://www.datocms-assets.com",
                    "/documents",
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {doc.title}
                </a>
                <br />
                <span className="description">{doc.description}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default BackgroundDocuments
