import {navigate} from "gatsby"
import React, {useEffect} from "react"
import {addSubscription} from "../utils/cookie-handlers"

interface Props {
  vmarketingListId?: string
  redirectUrl: string
}

const NewsletterForm = ({vmarketingListId, redirectUrl}: Props) => {
  const [target, setTarget] = React.useState<EventTarget>()

  const handleSubmit = (e: React.TargetedEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.target && setTarget(e.target)
  }

  useEffect(() => {
    if (target) {
      const form = target as HTMLFormElement
      const formData = new FormData(form)
      fetch(form.action, {
        method: form.method,
        headers: {},
        body: formData,
      }).then(async response => {
        if (response.status === 200) {
          console.log("Successfuly created subscription")
          addSubscription(vmarketingListId || "")
          navigate(redirectUrl)
        } else {
          const text = await response.text()
          console.error("An error occured while creating subscription: ", text)
          navigate(redirectUrl)
        }
      })
    }
  }, [target])

  return (
    <>
      <div className="downloadform">
        <form
          className="js-cm-form"
          id="subForm"
          action="/subscribe-to-newsletter"
          method="post"
          data-id={vmarketingListId}
          onSubmit={e => {
            handleSubmit(e)
          }}
        >
          <label>
            <b>E-mail:</b>{" "}
          </label>
          <input
            autoComplete="Email"
            aria-label="Email"
            className="js-cm-email-input qa-input-email"
            id="fieldEmail"
            maxLength={200}
            name="email"
            required
            type="email"
          ></input>
          <input type="hidden" name="listId" value={vmarketingListId}></input>
          <input
            style={{marginLeft: "0.5rem"}}
            type="submit"
            className="searchbutton"
            id="searchbutton"
            value="Ok"
          ></input>
        </form>
      </div>
    </>
  )
}

export default NewsletterForm
