import React from "react"
import {relevantLawsLegislation} from "src/interfaces/publication"

const LawsAndLegislation = ({
  relevantLawsLegislation,
}: {
  relevantLawsLegislation: relevantLawsLegislation[]
}) => {
  const activeLegislation = relevantLawsLegislation.filter(doc => {
    return doc.active
  })

  return (
    <>
      <h2 id="links" className="expandable">
        Relevante publicaties en websites
      </h2>
      <div className="publicationlinks">
        <ul>
          {activeLegislation.map(doc => {
            return (
              <li key={doc.title}>
                <a href={doc.url} target="blank">
                  {doc.title}
                  <span className="icon-external-link"></span>
                </a>
                <span className="url">{doc.url}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default LawsAndLegislation
