import React from "react"
import GuideDownload from "../guides/guide-download"
import {Guide} from "../../interfaces/publication"

const Guides = ({guides}: {guides: Guide[]}) => {
  return (
    <div className="guides">
      <h2>Gerelateerde handreikingen</h2>
      {guides.map(guide => {
        return (
          <GuideDownload
            key={guide.title}
            title={guide.title}
            subtitle={guide.subtitle}
            url={guide.document.url}
            documentUrl={guide.documentUrl}
          />
        )
      })}
    </div>
  )
}

export default Guides
