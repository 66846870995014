import React, {useEffect, useState} from "react"
import parse from "html-react-parser"
import DownloadModal from "../download-modal"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronRight} from "@fortawesome/free-solid-svg-icons"
import Cookies from "js-cookie"
import {navigate} from "gatsby"
import {getSubscriptions} from "../../utils/cookie-handlers"
import {Document} from "src/interfaces/publication"

interface Props {
  documents: Document[]
  label: string
  vmarketingListId?: string
  number: string
  numberSuffix?: string
}

const Documents = (props: Props) => {
  const activeDocuments = props.documents
    .filter(doc => {
      return doc.active
    })
    .sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
  const unarchivedDocuments = activeDocuments.filter(doc => {
    return !doc.archive
  })
  const archivedDocuments = activeDocuments.filter(doc => {
    return doc.archive
  })
  const title = "PGS-" + props.label.slice(3)

  const [showModal, setShowModal] = useState(false)

  const [alreadySubscribed, setAlreadySubscribed] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const handlePublicationClick = () => {
    alreadySubscribed
      ? navigate(
          unarchivedDocuments[0].contentUrl.replace(
            "https://www.datocms-assets.com",
            "/documents",
          ),
        )
      : toggleModal()
  }

  useEffect(() => {
    const subscriptions: string[] = getSubscriptions()
    if (Cookies.get("emailaddress")) {
      setAlreadySubscribed(true)
    } else if (
      props.vmarketingListId &&
      subscriptions.includes(props.vmarketingListId)
    ) {
      setAlreadySubscribed(true)
    }
  }, [])

  return (
    <>
      <div className="block">
        {!unarchivedDocuments[0] ? (
          <h3>Geen actuele publicaties beschikbaar</h3>
        ) : (
          <>
            <h2>{title}</h2>
            <h4>{unarchivedDocuments[0]?.subtitle}</h4>
            <div
              className={showModal ? "overlay" : " "}
              onClick={() => {
                toggleModal()
              }}
            ></div>
            <button
              className="button"
              onClick={() => {
                handlePublicationClick()
              }}
            >
              Bezoek meest actuele versie {unarchivedDocuments[0]?.subtitle}{" "}
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
            {showModal && (
              <>
                <DownloadModal
                  number={props.number}
                  numberSuffix={props.numberSuffix}
                  vmarketingListId={props.vmarketingListId}
                  contentUrl={unarchivedDocuments[0].contentUrl.replace(
                    "https://www.datocms-assets.com",
                    "/documents",
                  )}
                />
              </>
            )}
            {unarchivedDocuments.length > 1 && (
              <h3>Overige beschikbare publicaties</h3>
            )}
            {unarchivedDocuments.slice(1).map((doc, index) => {
              return (
                <a
                  className="version"
                  href={doc.contentUrl.replace(
                    "https://www.datocms-assets.com",
                    "/documents",
                  )}
                  key={index}
                >
                  <div>
                    <h4>
                      <span>{doc.subtitle}</span>
                      {doc.bobApproval && (
                        <span className="bob">Wettelijk vastgesteld</span>
                      )}
                    </h4>
                  </div>
                </a>
              )
            })}{" "}
          </>
        )}
      </div>
      <h2>Voorgaande publicaties</h2>
      {archivedDocuments.map((doc, index) => {
        return (
          <div className="documents" key={`doc ${index}`}>
            <a
              className="pubdl"
              href={doc.contentUrl.replace(
                "https://www.datocms-assets.com",
                "/documents",
              )}
            >
              <span className="pgs">
                {doc.erratum ? "erratum" : "PGS " + props.label.slice(3)}
              </span>
              <span className="cpr">{parse(doc.cprLabel)}</span>
              <span className="download pdf">
                <span className="title">{doc.title}</span>
                <span className="comments">{doc.subtitle}</span>
              </span>
            </a>
          </div>
        )
      })}
    </>
  )
}

export default Documents
