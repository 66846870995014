import Cookies from "js-cookie"

export const getSubscriptions = (): string[] => {
  const subscriptions = Cookies.get("newslettersubscriptions")
  return subscriptions ? subscriptions.split(",") : []
}

export const addSubscription = (listId: string): void => {
  const subscriptions = getSubscriptions()
  subscriptions.push(listId)
  Cookies.set("newslettersubscriptions", subscriptions.join(","), {
    expires: 360 * 15,
  })
}
