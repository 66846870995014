import React from "react"
import NewsletterForm from "../components/newsletter-form"

interface Props {
  vmarketingListId?: string
  number: string
  numberSuffix?: string
  contentUrl: string
}

const DownloadModal = (props: Props) => {
  return (
    <>
      <div id="dlwindow" className="downloadwindow">
        <h2>Bekijk PGS {props.number}</h2>
        <p>
          Door deze PGS publicatie te bekijken gaat u akkoord met de{" "}
          <a href="/gebruikersvoorwaarden.html">gebruikersvoorwaarden</a>.
        </p>
        <p>
          Om u in de toekomst op de hoogte te houden over belangrijke
          wijzigingen en actualisaties in PGS {props.number} bewaren we graag uw
          e-mailadres.
        </p>
        <NewsletterForm
          vmarketingListId={props.vmarketingListId}
          redirectUrl={props.contentUrl}
        />
        <div className="skip">
          <a href={props.contentUrl}>Deze stap overslaan</a>
        </div>
      </div>
    </>
  )
}

export default DownloadModal
