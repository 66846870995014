import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import parse from "html-react-parser"
import Documents from "../components/publications/documents"
import Guides from "../components/publications/guides"
import BackgroundDocuments from "../components/publications/background-documents"
import LawsAndLegislation from "../components/publications/laws-and-legislation"
import Planning from "../components/publications/planning"
import FaqList from "../components/publications/faq-list"
import {PublicationQuery} from "src/interfaces/publication"
import {SEO} from "../components/seo"

const PublicationTemplate = ({data, pageContext}: PublicationQuery) => {
  const {
    title,
    body,
    pgsLabel,
    subtitle,
    documents,
    guides,
    number,
    numberSuffix,
    backgroundInformation,
    relevantLawsLegislation,
    showInPlanning,
    faqJson,
    plannedRevisionEnd,
    plannedRevisionStart,
    currentRevisionEnd,
    currentRevisionStart,
    vmarketingListId,
  } = data.datoCmsPublication

  const zeroPad = (places: number, num: string) =>
    String(num).padStart(places, "0")

  return (
    <Layout currentLocation={pageContext.url}>
      <div className="publications">
        <div className="number">
          <img
            src={`/images/numbers/3d-number${
              numberSuffix
                ? zeroPad(4, String(number) + numberSuffix)
                : zeroPad(3, number)
            }.png`}
          />
        </div>
        <h1>{title}</h1>
        {subtitle && <p className="introduction">{subtitle}</p>}
        <br />
        {parse(body)}
        {documents.length > 0 && (
          <Documents
            number={number}
            numberSuffix={numberSuffix}
            documents={documents}
            label={pgsLabel}
            vmarketingListId={vmarketingListId}
          />
        )}
        {guides.length > 0 && <Guides guides={guides} />}
        {showInPlanning && (
          <>
            <h2>Actualisatie planning</h2>
            <div className="revision">
              <Planning
                years={{
                  planEnd: new Date(plannedRevisionEnd),
                  planStart: new Date(plannedRevisionStart),
                  curEnd: new Date(currentRevisionEnd),
                  curStart: new Date(currentRevisionStart),
                }}
              />
            </div>
            <ul className="legend">
              <li className="planned">
                <span></span>Planning
              </li>
              <li className="current">
                <span></span>Huidige herziening
              </li>
              <li className="link">
                Bekijk alle <a href="/planning">actualisaties</a>
              </li>
            </ul>
          </>
        )}
        {backgroundInformation.length > 0 && (
          <BackgroundDocuments backgroundInformation={backgroundInformation} />
        )}
        {relevantLawsLegislation.length > 0 && (
          <LawsAndLegislation
            relevantLawsLegislation={relevantLawsLegislation}
          />
        )}
        {faqJson && <FaqList faqJson={faqJson} />}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    datoCmsPublication(id: {eq: $id}) {
      title
      subtitle
      pgsLabel
      vmarketingListId
      number
      numberSuffix
      body
      showInPlanning
      faqJson
      plannedRevisionEnd
      plannedRevisionStart
      currentRevisionEnd
      currentRevisionStart
      guides {
        archive
        document {
          url
        }
        documentUrl
        title
        subtitle
      }
      documents {
        active
        archive
        date
        contentUrl
        cprLabel
        erratum
        subtitle
        title
        bobApproval
      }
      relevantLawsLegislation {
        active
        title
        url
      }
      backgroundInformation {
        active
        description
        document {
          url
        }
        title
      }
    }
  }
`

export default PublicationTemplate

export const Head = ({data}: PublicationQuery) => {
  return <SEO title={data.datoCmsPublication.title} />
}
