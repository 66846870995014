import React from "react"
import parse from "html-react-parser"
import {Faq, FaqSection} from "src/interfaces/publication"

const FaqList = ({faqJson}: {faqJson: string}) => {
  const faqObject = JSON.parse(faqJson)

  return (
    <>
      {faqObject.length <= 1 ? (
        <div className="questionContainer">
          <h2 className="autoopen expandable">Vraag en antwoord</h2>
          <div className="questions">
            <ul>
              {faqObject[0].faqs.map((faq: Faq) => {
                return (
                  <li key={faq.link}>
                    <a href={`/vraag-en-antwoord/${faq.link}`}>
                      {parse(faq.question)}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      ) : (
        <>
          <h2 className="noline">Vraag en antwoord</h2>
          {faqObject.map((section: FaqSection) => {
            return (
              <div className="questioncontainer" key={section.title}>
                {section.faqs && (
                  <>
                    <span title={section.title}></span>
                    <h2 className="expandable small">{section.title}</h2>
                    <div className="questions">
                      <ul>
                        {section.faqs.map((faq: Faq) => {
                          return (
                            <li key={faq.link}>
                              <a href={`/vraag-en-antwoord/${faq.link}`}>
                                {parse(faq.question)}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </>
      )}
    </>
  )
}

export default FaqList
